import React, { useState } from "react";
import axios from "axios";
import "./App.css";

// 1. import `ChakraProvider` component
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  ChakraProvider,
  Container,
  Divider,
  extendTheme,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};

const theme = extendTheme({ colors });

function App() {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [mp3File, setMP3File] = useState({ fileName: "", downloadUrl: "" });

  const handleInputChange = (event) => {
    setUrl(event.target.value);
  };

  const apiUrl = process.env.REACT_APP_API_HOST;

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      // Send POST request to backend to download and convert video to .wav
      const response = await axios.post(
        `${apiUrl}/api/convertFile`,
        {
          url,
        },
        {
          timeout: 900000, // set the timeout to 15 minutes
        }
      );
      // if (response && response.data) {
      //   setIsLoading(false);
      // }
      console.info("response.data: ", response.data);
      // Set download URL returned by backend
      if (response && response.data?.audioFile) {
        setMP3File({
          fileName: response.data.audioFile.fileName,
          downloadUrl: response.data.audioFile.downloadUrl,
        });
      }
      // reset input
      setUrl("");
    } catch (error) {
      setErrorMessage("An error occurred while converting the file");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ChakraProvider resetCSS theme={theme}>
      <Flex
        width={"100vw"}
        height={"100vh"}
        alignContent={"center"}
        justifyContent={"center"}
        className="main-bg"
      >
        <Container maxW="800px" m={5}>
          <Flex justify={"center"} m={5}>
            <Text
              bgGradient="linear(to-b, #fff59d, #d81b60)"
              bgClip="text"
              fontSize="4xl"
              fontWeight="extrabold"
              align={"center"}
              as="h1"
              mb={4}
              width="auto"
            >
              Audio Downloader
            </Text>
          </Flex>
          <Card size={"lg"} bg="blackAlpha.600" color="orange">
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormControl
                  isInvalid={errorMessage}
                  isRequired
                  isDisabled={isLoading}
                >
                  <FormLabel>Enter a YouTube URL</FormLabel>
                  <Flex justifyContent="flex-start" alignItems="center">
                    <Input
                      colorScheme="orange"
                      type="text"
                      placeholder="ex: https://www.youtube.com/watch?v=TO-_3tck2tg"
                      value={isLoading ? "Please wait ..." : url}
                      onChange={handleInputChange}
                      borderColor="orange"
                      borderRight="none"
                      borderRightRadius={0}
                    />
                    <Button
                      colorScheme="orange"
                      isLoading={isLoading}
                      type="submit"
                      disabled={url}
                      borderLeft="none"
                      borderLeftRadius={0}
                      rightIcon={<DownloadIcon />}
                    >
                      Convert
                    </Button>
                  </Flex>
                  {errorMessage && (
                    <FormErrorMessage>{errorMessage}</FormErrorMessage>
                  )}
                </FormControl>
              </form>
            </CardBody>

            {!errorMessage &&
              !isLoading &&
              mp3File.fileName &&
              mp3File.downloadUrl && (
                <Box bg="blackAlpha.800" border="2px white">
                  <Divider />
                  <CardFooter>
                    <Flex justifyContent="center">
                      {/* <Button
                        onClick={() => window.open(mp3File.downloadUrl)}
                        colorScheme="red"
                        variant="solid"
                      >
                        Download mp3
                      </Button> */}
                      <Link href={mp3File.downloadUrl} isExternal download>
                        Download {mp3File.fileName}
                      </Link>
                    </Flex>
                  </CardFooter>
                </Box>
              )}
          </Card>
        </Container>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
